<template>
    <div class="modal-operatorseditheader">
        <div class="modal-operatorseditheaderinfo">
            <span class="operatorsavatarcontainer" v-if="user.photoURL">
                <img class="operatorsavatar operatorsavatarmodal" :src="user.photoURL">
                <span class="avatarEditSpan">
                    <div class="operatorsavatarmodalshadow"></div>
                    <div class="operatorsavatarmodalshadowtext"><b-icon icon="image"></b-icon> <p>Atualizar</p></div>
                </span>
            </span>
            <span class="operatorsavatarcontainer" v-else>
                <img class="operatorsavatar operatorsavatarmodal" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c">
                <span class="avatarEditSpan">
                    <div class="operatorsavatarmodalshadow"></div>
                    <div class="operatorsavatarmodalshadowtext"><b-icon icon="image"></b-icon> <p>Atualizar</p></div>
                </span>
            </span>
            <div>
                <div class="modal-operatorsname">{{user.name}}</div>
                <div class="modal-operatorsjob">{{user.job}}</div>
                <!-- <div><b-icon icon="star-fill" style="color:#f1c40f"></b-icon><b-icon icon="star-fill" style="color:#ccc"></b-icon></div> -->
                <b-form-rating style="padding:0" v-model="user.stars" no-border size="sm" variant="warning"></b-form-rating>
            </div>
        </div>
        <div>
            <b-button class="modal-operatorseditheaderbtn">Desativar</b-button>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'user'
    ]
}
</script>

<style scoped>
    .operatorsavatar{
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    .modal-operatorseditheader{
        border-bottom: 0.5px solid #eee;
        padding-bottom: 15px;
    }
    .operatorsavatarcontainer{
        position: relative;
        cursor: pointer;
        margin-right: 10px;
    }
    .operatorsavatarmodal{
        height: 75px;
        width: 75px;
        background-color: black;
    }
    .operatorsavatarmodalshadow{
        width: 75px;
        height: 35px;
        background-color: #000;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        border: 10px solid #000;
        border-bottom: 0;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        opacity: 0.75;
    }
    .operatorsavatarmodalshadowtext{
        display: flex;
        align-items: center;
        flex-direction: column;
        top: 85%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #fff;
        position: absolute;
        font-size: 0.7em;
    }
    .modal-operatorseditheader{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .modal-operatorseditheaderinfo{
        display: flex;
    }
    .modal-operatorsname{
        font-size: 1.2em;
        font-weight: 500;
        color: #555;
    }
    .modal-operatorsjob{
        color: #999;
        font-size: 0.9em;
    }
    .modal-operatorseditheaderbtn{
        background-color: hsl(360deg 88% 70%);
        border-color: hsl(360deg 88% 70%);
    }
    .avatarEditSpan{
        display: none;
    }
    .operatorsavatarcontainer:hover .avatarEditSpan{ 
        display: inline;
    }
</style>